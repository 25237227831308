import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Cookies from 'js-cookie'

// the translations
// (tip move them in a JSON file and import them)
const resources = {
    en: {
        translation: {
            "menu-home": "Home",
            "menu-products": "Products",
            "menu-stores": "Stores",
            "menu-courses": "Courses",
            "menu-contact": "Contact",
            "home-page-section-title": "SUPERIOR QUALITY",
            "home-page-section-sub-title": "Absolute satisfaction",
            "home-page-section-small-description": "Durable, easy to clean bakeware with an environmentally friendly non-stick coating. The collection includes a wide variety of products, from classic cake pans to specialty shapes and sizes. Complimentary displays are available with orders of assorted products.",
            "home-page-section-preview-1-title": "Products",
            "home-page-section-preview-1-description": "Discover all the variety of products we offer in our catalog",
            "home-page-section-preview-2-title": "Where to buy",
            "home-page-section-preview-2-description": "Find out in wich stores our products are available",
            "home-page-section-preview-3-title": "Contact Us",
            "home-page-section-preview-3-description": "Get in touch with us for any inquiries",
            "contact-page-title": "CONTACT",
            "contact-page-write-us": "Contact us",
            "contact-page-write-us-subtitle": "ALWAYS READY TO ANSWER YOU",
            "contact-page-form-subject": "Subjet",
            "contact-page-form-message": "Message",
            "contact-page-form-send-message": "Submit your message",
            "contact-page-form-send-message-success": "Message sent successfully !",
            "contact-page-form-send-message-fail": "An error has occurred. The message could not be sent",
            "stores-page-title": "STORES",
            "stores-page-list": "Stores list:",
            "products-page-title": "PRODUCTS",
            "products-page-select-product": "Select a product category",

        }
    },
    fr: {
        translation: {
            "menu-home": "Accueil",
            "menu-products": "Produits",
            "menu-stores": "Magasins",
            "menu-courses": "Formations",
            "menu-contact": "Contact",
            "contact-page-title": "CONTACT",
            "home-page-section-title": "QUALITÉ SUPÉRIEURE",
            "home-page-section-sub-title": "Satisfaction absolue",
            "home-page-section-small-description": "Durable et facile à nettoyer, La Pâtisserie possède un revêtement anti-adhésif écologique. La collection comprend une garnde variété de produits; tels que les moules de gâteaux aux formes classiques et même celles de taille et forme spéciales. Des présentoirs sont disponibles avec des commandes de produits assortis.",
            "home-page-section-preview-1-title": "Produits",
            "home-page-section-preview-1-description": "Découvrez toute la variété de produits que nous offrons dans notre catalogue",
            "home-page-section-preview-2-title": "Où acheter",
            "home-page-section-preview-2-description": "Découvrez dans quels magasins nos produits sont disponibles",
            "home-page-section-preview-3-title": "Contactez-nous",
            "home-page-section-preview-3-description": "Contactez-nous pour toute demande",
            "contact-page-write-us": "Contactez-nous",
            "contact-page-write-us-subtitle": "TOUJOURS PRÊTS À VOUS RÉPONDRE",
            "contact-page-form-subject": "Sujet",
            "contact-page-form-message": "Message",
            "contact-page-form-send-message": "Envoyer votre message",
            "contact-page-form-send-message-success": "Message envoyé avec succès !",
            "contact-page-form-send-message-fail": "Une erreur est survenue. Le message n'a pas pu être envoyé",
            "stores-page-title": "MAGASINS",
            "stores-page-list": "Liste des magasins:",
            "products-page-title": "PRODUITS",
            "products-page-select-product": "Selectionnez une catégorie de produit",
        }
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: Cookies.get('lang') ? Cookies.get('lang') : 'fr',
        fallbackLng: Cookies.get('lang') ? Cookies.get('lang') : 'fr', // use en if detected lng is not available

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;