import React, { useEffect, useState, useRef } from "react";
import { useAppContext } from "../../useAppState";
import './Menu.scss'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-notifications/lib/notifications.css';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import logo from "../../images/coolkitchen.png";


const Menu = (props) => {

    const { t } = useTranslation();
    const { state, actions } = useAppContext();

    const [navElements, setNavElements] = useState({});
    const [burgerClass, setBurgerClass] = useState('hamburger hamburger--collapse');
    const [mobileMenuClass, setMobileMenuClass] = useState('menu-mobile');

    const changeLangHandler = (e) => {
        e.preventDefault();
        if (state.lang === "fr") {
            actions.changeLang('en')
        } else {
            actions.changeLang('fr')
        }
    };

    useEffect(() => {
        setNavElements({
            home: {
                id: 'home',
                label: t('menu-home'),
                path: '/'
            },
            products: {
                id: 'products',
                label: t('menu-products'),
                path: '/products'
            },
            contact: {
                id: 'contact',
                label: t('menu-contact'),
                path: '/contact'
            },
        });
        // setupGreetingMsg();
        // setSearchPlaceholder(t('menu-search-placeholder'));

    }, [state.lang]);

    const handleBurgerClick = (e) => {
        if (burgerClass.indexOf('is-active') !== -1) {
            setBurgerClass('hamburger hamburger--collapse');
            setMobileMenuClass('menu-mobile');
        } else {
            setBurgerClass('hamburger hamburger--collapse is-active');
            setMobileMenuClass('menu-mobile menu-mobile-open');
        }

    };


    return (
        <div className={"menu-top-container"}>
            <div className={"menu-top-cnt"}>
                <div className={"menu-top-bar"}>
                    <Link to={'/'}>
                        <div className={'logo'} >
                            <img src={logo} alt="logo" />
                        </div>
                    </Link>
                    <nav className={'menu-desktop'}>
                        <ul>
                            {
                                Object.keys(navElements).map(function (key, index) {
                                    if (key === 'profil') return;

                                    return (
                                        <li key={'li-' + key}><Link to={navElements[key].path}>{navElements[key].label}</Link></li>
                                    )
                                })
                            }
                        </ul>

                        <div className={"lang-btn"}>
                            <a href="#" className="lang-selector" onClick={changeLangHandler}>{state.lang === "fr" ? "EN" : "FR"}</a>
                        </div>
                    </nav>

                    <nav className={mobileMenuClass}>
                        <ul>
                            {
                                Object.keys(navElements).map(function (key, index) {
                                    return (
                                        <li key={'li-' + key}><Link to={navElements[key].path} onClick={handleBurgerClick}>{navElements[key].label}</Link></li>
                                    )
                                })
                            }

                            <br />
                            <li className={"menu-btn-style menu-btn-lang-switch"} onClick={changeLangHandler} ><span>{state.lang === "fr" ? "EN" : "FR"}</span></li>
                        </ul>
                    </nav>
                    <button className={burgerClass} onClick={handleBurgerClick} type="button">
                        <span className="hamburger-box">
                            <span className="hamburger-inner"></span>
                        </span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Menu;