import React, { Suspense, useState, useEffect } from 'react';
import { AppContext, useAppState } from './useAppState'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import MoonLoader from 'react-spinners/MoonLoader';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import Menu from './components/menu/Menu'

const Home = React.lazy(() => import('./pages/Home/Home'));
const Products = React.lazy(() => import('./pages/Products/Products'));
const Stores = React.lazy(() => import('./pages/Stores/Stores'));
const Contact = React.lazy(() => import('./pages/Contact/Contact'));

function App() {

    const { t, i18n } = useTranslation();
    const { state, actions } = useAppState();

    useEffect(() => {
        if (!["/", "/products", "/stores", "/contact"].includes(window.location.pathname)) {
            console.log(window.location.pathname)
            window.history.pushState("", "", "/");
        }
    }, [])

    return (
        <AppContext.Provider value={{ state, actions }} >

            <Router basename={state.baseName}>
                <div className="App">
                    <Suspense fallback={<div className="fallback-black-loader"><MoonLoader color={'#cd1236'} /></div>}>

                        <Menu />

                        <Routes >
                            <Route exact path="/" element={<Home />} />
                            <Route exact path="/products" element={<Products />} />
                            <Route exact path="/stores" element={<Stores />} />
                            <Route exact path="/contact" element={<Contact />} />
                            <Route path="*" element={<Home />} />
                        </Routes>

                        <footer>
                            © 2021 Orly Cuisine. All Rights Reserved.
                        </footer>
                    </Suspense>
                </div>
            </Router>
        </AppContext.Provider>
    );
}

export default App;
